import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  inject,
} from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { ApolloError } from '@apollo/client/errors';
import { CreateChannelInput } from '@designage/gql';
import { BaseDialogComponent } from '@desquare/components/common/src/base-dialog/base-dialog.component';
import { GQLErrorTypes } from '@desquare/enums';
import { ICreateChannelForm, IVerifyDeviceForm } from '@desquare/interfaces';
import {
  ChannelService,
  DeviceDataService,
  ToasterService,
} from '@desquare/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ChannelFormComponent } from '../channel-form/channel-form.component';
import { DeviceFormComponent } from '@designage/app/device/device-form/device-form.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';

interface IFormValues {
  channel?: ICreateChannelForm;
  device?: IVerifyDeviceForm;
}

interface IFormStep<T> {
  value?: T;
  isValid: boolean;
}

@Component({
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    BaseDialogComponent,
    ChannelFormComponent,
    DeviceFormComponent,
    LoaderComponent,
  ],
  selector: 'app-create-channel-wizard',
  templateUrl: './create-channel-wizard.component.html',
  styleUrls: ['./create-channel-wizard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateChannelWizardComponent {
  destroyRef = inject(DestroyRef);

  @Input() profileId!: string;

  form = new FormGroup({});
  isInvalidCode: boolean = false;
  isSkipProvisionDevice = false;

  // Wizard State
  TOTAL_STEP = 2;
  currentStep = 1;

  // each step has their respective headers
  wizardHeader = ['CREATE_CHANNEL', 'CREATE_DEVICE'];

  // loader
  loading = false;
  loaderMessage = '';

  constructor(
    private deviceService: DeviceDataService,
    private channelService: ChannelService,
    private toasterService: ToasterService,
    private modal: NgbActiveModal
  ) {}

  back() {
    this.form.updateValueAndValidity();
    this.currentStep = this.currentStep - 1;
  }

  cancel() {
    this.modal.dismiss();
  }

  next() {
    this.form.updateValueAndValidity();
    this.currentStep = this.currentStep + 1;
  }

  submit() {
    const { channel, device } = this.form.value as IFormValues;

    if (!channel) return;
    const provisionDeviceInput = this.isSkipProvisionDevice
      ? undefined
      : this.deviceService.toProvisionDeviceInput(device!);

    const { name, description, layoutId } = channel;
    const createChannelInput: CreateChannelInput = {
      provisionDeviceInput,
      name,
      description,
      profileId: this.profileId,
      layoutId,
    };

    this.loading = true;
    this.loaderMessage = createChannelInput.provisionDeviceInput
      ? 'CREATING_CHANNEL_WITH_DEVICE'
      : 'CREATING_CHANNEL';

    this.channelService
      .createChannel(createChannelInput)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result) => {
          const { data } = result;
          if (data?.createChannel.isSuccessful) {
            this.toasterService.success('CREATE_CHANNEL_SUCCESS');
          } else {
            this.toasterService.error('UNKNOWN_ERROR');
          }
          this.loading = false;
          this.modal.close();
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('Provisioning Device Error:', gqlError);
            if (
              gqlError.extensions &&
              gqlError.extensions.code === GQLErrorTypes.INTERNAL_SERVER_ERROR
            ) {
              this.toasterService.error('PROVISIONING_DEVICE_ERROR');
            } else {
              if (gqlError.extensions?.code === 'NOT_FOUND_ERROR') {
                this.isInvalidCode = true;
              }

              console.error('Provisioning Device Error:', gqlError);
              this.toasterService.handleGqlError(gqlError);
            }
          });

          this.loading = false;
        },
      });
  }
}
