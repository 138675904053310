<app-base-dialog
  headerText="Step {{ currentStep }}: {{
    wizardHeader[currentStep - 1] | translate
  }}"
>
  <!-- body -->
  <div class="modal-body">
    <!-- loader -->
    @if(loading){
    <app-loader [message]="loaderMessage" />
    } @else {
    <!-- DEBUG -->
    <!-- <pre style="color: white;">{{ form.value | json }}</pre> -->

    <!-- channel form  -->
    @if(currentStep === 1){
    <div>
      <app-channel-form
        [profileId]="profileId"
        [isDashboardView]="true"
        [parentFormGroup]="form"
        [formId]="channel"
        [newChannel]="true"
      ></app-channel-form>
    </div>
    }
    <!-- device form  -->
    @if(currentStep === 2){
    <div>
      <app-device-form
        [profileId]="profileId"
        [isInvalidCode]="isInvalidCode"
        [isHideChannelSelection]="true"
        [parentFormGroup]="form"
      ></app-device-form>
    </div>
    } }
  </div>

  <!-- footer -->
  @if(!loading){
  <div class="modal-footer d-flex justify-content-between">
    <div>
      @if(currentStep > 1){
      <button class="btn btn-primary mx-1" (click)="back()">
        {{ 'BACK' | translate }}</button
      >}
    </div>

    <div>
      <button class="btn btn-outline-warning mx-1" (click)="cancel()">
        {{ 'CANCEL' | translate }}
      </button>
      @if(currentStep < TOTAL_STEP){
      <button
        class="btn btn-outline-success mx-1"
        [disabled]="form.controls.channel.invalid"
        (click)="next()"
      >
        {{ 'NEXT' | translate }}</button
      >} @if(currentStep === 2){
      <button
        class="btn btn-success mx-1"
        [disabled]="form.controls.channel.invalid"
        (click)="isSkipProvisionDevice = true; submit()"
      >
        {{ 'SKIP_PROVISIONING' | translate }}</button
      >} @if(currentStep === TOTAL_STEP){
      <button
        class="btn btn-success mx-1"
        [disabled]="form.invalid"
        (click)="submit()"
      >
        {{ 'PROVISION' | translate }}</button
      >}
    </div>
  </div>
  }
</app-base-dialog>
